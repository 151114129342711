<template>
    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                <h4>Users</h4>
         
            </div>
            <div class="card p-fluid">
                <DataTable :value="users" 
                showGridlines 
                :scrollable="true" 
                responsiveLayout="scroll"
                stripedRows 
                scrollHeight="500px" 
                :loading="loading"
                :resizableColumns="true" 
                >
                   
                    <Column field="id" header="ID" headerStyle="width: 115px;" sortable>
                        <template #body="slotProps">
                            {{ slotProps.index + 1 }}
                        </template>
                    </Column>
                    <Column field="name" sortable>
                        <template #header>
                            Name
                        </template>
                        <template #body="slotProps">
                            <div >
                                {{slotProps.data.first_name}} {{slotProps.data.last_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="email" header="Email" sortable>{{ slotProps.data.email}}</Column>
                    <Column field="status" header="Status" sortable  >
                        <template #body="slotProps">
                            <div v-if="slotProps.data.is_enabled">Enabled</div>
                            <div v-else-if="!slotProps.data.is_enabled">Disabled</div>
                        </template>
                    </Column>
                    <Column field="role" header="Role" sortable >
                        <template #body="slotProps">
                            <div >{{ slotProps.data.role_name }}</div>

                        </template>
                    </Column>
                    <Column field="inviteStatus" header="Invite Status" sortable >
                        <template #body="slotProps">
                        <span v-if="slotProps?.data?.last_login===null" class="opportunityLink" @click="resendInvite(slotProps.data)">Resend Invite</span>
                        <span v-else>Confirmed</span>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import UsersService from '../../service/UsersService';
import CurrentUserService from '../../service/CurrentUserService';

export default {
    data() {
        return {
            users: null,
            currentUser: null
        }
    },
    usersService: null,
    userService: null,
    created() {
        this.usersService = new UsersService();
        this.userService = new CurrentUserService();
    },
    mounted() {
        const accountId=localStorage.getItem('accountId');
        this.usersService.getUsers(accountId).then(data => this.users = data);
    },
    methods: {
        resendInvite(user) {
            this.usersService.resendInvite(user.email).then(data => {
                this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Invite Resent' });
            });
        }
    }
}
</script>



<style lang="scss" scoped>

.opportunityLink {
    color: darkgreen;
    text-decoration: underline;
    cursor: pointer;
}

.opportunityLink:hover {
    
}
</style>