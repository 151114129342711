<template>

    <div class="p-grid">
        <div class="p-col-12 p-md-12">
            <div class="card p-fluid">
                
                <h4>Shared Document Library</h4>
                <h6 :style="userIsACX ? 'display:none;' : ''">
                    <i style="font-size: 2rem;" class="pi pi-check-circle p-mr-2 p-p-2 white-bgcolor blue-color "></i>
                    (This page is a work in progress!  The content below is fictional but illustrates how our Members and Parters will be able to share Documents of various kinds.)
                </h6>
                
            </div>
        </div>
		<div class="p-col-12">
			<div class="card">
				<TreeTable :value="treeTableValue" 
                    class="p-treetable-sm" 
                    selectionMode="single" 
                    :filters="filters"
                    :resizableColumns="true" 
                    columnResizeMode="fit"
                    v-model:selectionKeys="selectedTreeTableValue">

					<template #header>
						Contracts, SOWs and other documents associated with your Arena(s) 
					</template>

                    <Column field="name" header="Name" :expander="true" headerStyle="width: 550px">
                        <template #filter>
                            <InputText type="text" v-model="filters['name']" class="p-column-filter" />
                        </template>
                        <template #body="slotProps">
                            <i v-if="slotProps.node.data.fileType == 'PDF'" style="font-size: 1.2rem; color:red;" class="pi pi-file-pdf p-p-2 "></i>
                            <i v-else-if="slotProps.node.data.fileType == 'MP4'" style="font-size: 1.2rem; color:purple;" class="pi pi-youtube p-p-2 "></i>
                            <i v-else-if="slotProps.node.data.fileType == 'XLSX'" style="font-size: 1.2rem; color:darkgreen; back-color: green;" class="pi pi-file-excel p-p-2 "></i>
                            <i v-else-if="slotProps.node.data.fileType == 'DOCX'" style="font-size: 1.2rem; color:blue;" class="pi pi-book p-p-2 "></i>
                            <i v-else></i>
                           {{slotProps.node.data.name}}
                        </template>
                    </Column>					

					<Column field="fileType" header="File Type" headerStyle="width: 6em"></Column>
					<Column field="dateTime" header="Date" headerStyle="width: 150px"></Column>
					<Column header="View/Download">
                        <template #body="">
                            <div class="fileLink">Download...</div>
                        </template>
                    </Column>
					<Column field="size" header="Size" headerStyle="width: 6em"></Column>
				</TreeTable>
			</div>
		</div>

    </div>
</template>


<script>
	import NodeService from '../service/NodeService'
    import CurrentUserService from '../service/CurrentUserService';

	export default {
		data() {
			return {
                filters: {},
				treeTableValue: null,
				selectedTreeTableValue: null,
                userIsACX: false
			}
		},
		nodeService: null,
		created() {
			this.nodeService = new NodeService();
            this.userService = new CurrentUserService();
		},
		mounted() {
			this.nodeService.getDocumentLibraryNodes().then(data => this.treeTableValue = data);
            this.userIsACX = this.userService.marketplaceUser.userRole.roleKey.startsWith("acx:");
		},
	}

</script>

<style scoped>
.fileLink {
    color: darkgreen;
    text-decoration: underline;
    cursor: pointer;
}
</style>
