import axios from 'axios';

import CurrentUserService from "./CurrentUserService";
const currentUser = new CurrentUserService();
currentUser.refreshCognitoJwt();
let jwt = currentUser.jwt;
export default class UsersService {
//get the token

    async getUsers(accountId) {
        const res = await axios.get(process.env.VUE_APP_COMMON_BASE_URL + `v1/users?account_id=${accountId}`, {
            headers: {
                'Authorization': `${jwt}`
            }
        });
        return res.data;
    }
    async resendInvite( email) {
        const res = await axios.post(process.env.VUE_APP_COMMON_BASE_URL + `v1/user/resend-signup`, {new_user_email:email}, {
            headers: {
                'Authorization': `${jwt}`
            }
        });
        return res.data;
    }

}
